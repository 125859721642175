/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useLocation } from 'react-router'
import { GCP_STORAGE_BASE_URL, LESSON_PLAYER_URL } from '../../../config'

type Props = {
  videoInfo?: any,
  buyInfo?: any
}

const LessonHeader: React.FC<Props> = ({ videoInfo, buyInfo }) => {
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const [currentVideoName, setCurrentVideoName] = useState<string | null>(null);
  
  const handleStartClick = (videoUrl: string, name: string) => {
    if (buyInfo && buyInfo.status === 'active' && buyInfo.payment_status === 'approved') {
      setCurrentVideo(videoUrl);
      setCurrentVideoName(name);
    }
  };
  
  const renderChapters = (chapters: any) => {
    return chapters.map((chapter: any) => (
      <div key={chapter.id} className="d-flex justify-content-between align-items-center py-2 border-bottom">
        <div>
          <strong>{chapter.id}.</strong> {chapter.name} <span>({chapter.duration})</span>
        </div>
        <button className={`btn ${chapter.buttonLabel === 'PREVIEW' ? 'btn-info' : 'btn-primary'}`}>
          {chapter.buttonLabel}
        </button>
      </div>
    ));
  };

  return (
    <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className="row py-5">
              <div className="col-12 text-center mb-4">
                {videoInfo.icon && (
                  <img 
                    src={`${GCP_STORAGE_BASE_URL}${videoInfo.icon}`} 
                    alt='ICT Kathurusingha' 
                    className="w-50 h-100 rounded"
                  />
                )}
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12 text-center">
                <h3 className="mt-3">{videoInfo.name}</h3>
              </div>
            </div>
            <div className="row justify-content-center text-center gap-5">
              <div className="col-10 ">
                <p>{videoInfo.description}</p>
              </div>
            </div>
            <div className="row justify-content-center py-5">
              <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-between align-items-center p-3 border rounded bg-light gap-3">
                <div>
                  <i className="bi bi-tag me-2 text-primary"></i>
                  <span>Price: <strong>Rs.{videoInfo.price}</strong></span>
                </div>
                <div>
                  <i className="bi bi-play-circle me-2 text-primary"></i>
                  <span>Videos: <strong>1</strong></span>
                </div>
                {videoInfo.duration != 0 && <div>
                  <i className="bi bi-clock me-2 text-primary"></i>
                  <span>Length: <strong>{videoInfo.duration}</strong></span>
                </div>}
              </div>
            </div>
    
            
            {/* Trailer Section */}
              {videoInfo.trailer && (
                <div className="row justify-content-center my-4 py-5">
                  <div className="col-md-8 col-sm-12">
                    <div className="ratio ratio-16x9">
                      <iframe
                        src={videoInfo.trailer}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="rounded"
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
    
    
            {/* Lessons Section */}
            <div className="row text-center py-5">
              <h4>Watch Chapter Now</h4>
              <p>පහත chapter දැකීමට ඔබට ලියාපදිංචි විය යුතුය</p>
            </div>
            <div className="row justify-content-center py-5">
              <div className="col-12 col-md-6">
                <h5>What will you learn from this course</h5>
                <div className="d-flex justify-content-between align-items-center py-2 border-bottom">
                    <div>
                      <strong>1.</strong> {videoInfo.name} <span>{videoInfo.duration ? `(${videoInfo.duration})` : ""}</span>
                      </div>
                      {buyInfo && buyInfo.payment_status === 'approved' && buyInfo.status === 'active' ? 
                        (
                          <button 
                            className='btn btn-sm btn-success mx-5'
                            onClick={() => handleStartClick(videoInfo.url, videoInfo.name)}
                          >
                            START
                          </button>
                        ) : (
                          <button 
                            className='btn btn-sm btn-secondary mx-5' 
                            disabled
                            >
                            START
                          </button>
                        )}
                </div>
              </div>
            </div>
          </div>
          {currentVideo && (
            <div className="row d-flex flex-column align-items-center text-center my-5 py-5 mx-5">
              <div className="col-md-8 col-sm-12">
                <h4 className="pb-5 fw-bold">{currentVideoName}</h4>
                <div className="ratio ratio-16x9">
                  <iframe
                    src={LESSON_PLAYER_URL + currentVideo}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="rounded"
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
  
  )
}

export { LessonHeader }
function async(video: any) {
  throw new Error('Function not implemented.')
}

